import { createContext } from 'react';
import authState, { initialAuthState } from 'context/state/authState';
import loadingState, { initialLoadingState } from 'context/state/loadingState';

const initialState = {
  auth: initialAuthState,
  loading: initialLoadingState,
};

export const AppContext = createContext({ ...initialState });

const storage = () => ({
  auth: authState(),
  loading: loadingState(),
});

export default storage;
