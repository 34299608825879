import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { thunk } from 'redux-thunk';

import rootReducer from './reducers';

const persistConfig = {
  key: 'kopkara_fe_konduktor_app_v1',
  storage: storage,
  timeout: null,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));

// Middleware: Redux Persist Persister
let persistor = persistStore(store);
// Exports
export { store, persistor };
