import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import useAppState from 'context/useAppState';
import Page from 'routes/index';
import Loading from 'components/Loading';
import useApp from './useApp';

function App() {
  const { pathname } = useLocation();

  const { isOpen } = useAppState((state) => state.loading);

  const { checkCredential, scrollToTop, loading } = useApp();

  useEffect(() => {
    checkCredential();
    scrollToTop();
  }, [pathname, checkCredential, scrollToTop]);

  if (loading)
    return <div className="fixed inset-0 h-screen w-full bg-black/50">loading credential...</div>;

  return (
    <>
      <Page />
      <Toaster />
      {!isOpen ? <></> : <Loading />}
    </>
  );
}

export default App;
